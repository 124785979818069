import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createIncidentMessage, generateIncidentSummary } from '~/api';
import { BasicButton, Separator, Textarea } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { sentry } from '~/sentry';

export const CreateIncidentMessagePage = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const [text, setText] = useState('');
  const navigate = useNavigate();
  const copy = useCopy();

  const create = useMutation({
    mutationFn: async ({ text }: { text: string }) => {
      const { incidentMessage } = await createIncidentMessage({
        incidentId,
        text,
      });

      await generateIncidentSummary({
        orgId: incidentMessage.orgId,
        incidentId,
      });
    },
    onSuccess: async () => {
      navigate(`/incidents/${incidentId}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <DashboardLayout headerTitle={copy.get('addMessage')}>
      <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
        <form
          className="space-y-6 md:space-y-10"
          onSubmit={(event) => {
            event.preventDefault();

            if (!text) return;

            create.mutate({
              text,
            });
          }}
        >
          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('whatIsMessage')}
            </div>

            <Separator />

            <div className="p-6">
              <Textarea
                value={text}
                onChange={(event) => setText(event.target.value)}
                rows={10}
              />
            </div>
          </div>

          <BasicButton
            className="w-full"
            type="submit"
            disabled={create.isPending || !text}
          >
            {create.isPending ? (
              <Loader2 className="h-6 w-6 animate-spin" />
            ) : (
              copy.get('save')
            )}
          </BasicButton>
        </form>

        <input
          id="hidden"
          className="pointer-events-none fixed left-full top-full -z-50 h-0 w-0 opacity-0"
          type="text"
        />
      </div>
    </DashboardLayout>
  );
};
