import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { listOrgMembers, sendIncidentAlert } from '~/api';
import { BasicButton, Separator } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { IncidentMembers, IncidentProvider } from '~/components/incident';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { useIncident } from '~/hooks/incident';
import { sentry } from '~/sentry';
import { formatDateTime } from '~/utils';

export const SendIncidentAlertPage = () => {
  const { incidentId } = useParams<{ incidentId: string }>();

  return (
    <IncidentProvider incidentId={incidentId}>
      <SendIncidentAlertComponent />
    </IncidentProvider>
  );
};

export const SendIncidentAlertComponent = () => {
  const incident = useIncident();
  const navigate = useNavigate();
  const copy = useCopy();
  const alertSent = copy.get('alertSent');

  const { data: members } = useQuery({
    queryKey: ['members'],
    queryFn: async () => {
      const { members } = await listOrgMembers({});

      return members;
    },
  });

  const send = useMutation({
    mutationFn: async () => {
      const { incidentAlertId } = await sendIncidentAlert({
        incidentId: incident.id,
      });

      return incidentAlertId;
    },
    onSuccess: (incidentAlertId) => {
      toast.error(alertSent);

      navigate(`/incidents/${incident.id}/alerts/${incidentAlertId}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  if (!members) return null;

  return (
    <DashboardLayout
      headerTitle={copy.get('sendAlertToMembers')}
      back={`/incidents/${incident.id}`}
    >
      <div className="space-y-20 lg:space-y-24">
        <div className="mx-auto w-full max-w-5xl space-y-6">
          <div className="rounded-lg border">
            <div className="space-y-2 p-6">
              <div className="font-medium text-muted-foreground">
                {formatDateTime(incident.date, 'D MMM YYYY')}
              </div>

              <h1 className="text-2xl font-bold md:text-3xl md:leading-normal">
                {incident.title}
              </h1>
            </div>
          </div>

          <div className="space-y-10 rounded-lg border px-6 py-10 text-center text-lg md:py-16">
            <p
              className="mx-auto max-w-xl"
              dangerouslySetInnerHTML={{
                __html: copy.get('thisWillSendAlert'),
              }}
            />

            <BasicButton
              disabled={send.isPending}
              onClick={() => send.mutate()}
              variant="destructive"
            >
              {send.isPending ? (
                <Loader2 className="h-6 w-6 animate-spin" />
              ) : (
                <>
                  {copy.get('sendAlertToXMembers', {
                    membersTotal: members?.length,
                    s: members?.length !== 1 ? 's' : '',
                    e: members?.length !== 1 ? 'e' : '',
                    i: members?.length !== 1 ? 'i' : 'o',
                  })}
                </>
              )}
            </BasicButton>
          </div>
        </div>

        <Separator />

        <IncidentMembers members={members} />
      </div>
    </DashboardLayout>
  );
};
