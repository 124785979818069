import { Badge, BasicButton, Link, Separator } from '~/components';
import { User } from '~/db';
import { useUser } from '~/hooks/auth';
import { useCopy } from '~/hooks/copy';
import { numberWithCommas } from '~/utils';

export const IncidentMembers = ({ members }: { members: User[] }) => {
  const { user } = useUser();
  const copy = useCopy();

  return (
    <div className="mx-auto w-full max-w-5xl space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border p-6 md:flex-row md:items-center">
        <h1 className="text-2xl font-bold">
          {copy.get('members')} ({numberWithCommas(members.length)})
        </h1>

        <Separator className="md:hidden" />

        <Link className="md:ml-auto" href="/members/invite">
          <BasicButton className="w-full">
            {copy.get('inviteMember')}
          </BasicButton>
        </Link>
      </div>

      {members.length > 0 && (
        <div className="mx-auto w-full max-w-5xl overflow-hidden rounded-lg border">
          {members.map((member, memberIndex) => {
            const isCurrentUser = member.id === user.id;

            return (
              <div className="hover:bg-muted/40" key={member.id}>
                {memberIndex !== 0 && <Separator />}

                <Link href={`/members/${member.id}`}>
                  <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:gap-14 lg:py-6 xl:py-8">
                    <div className="flex gap-4">
                      <div className="font-semibold">{member.name}</div>

                      {isCurrentUser && (
                        <Badge variant="secondary">{copy.get('you')}</Badge>
                      )}
                    </div>

                    <div className="text-muted-foreground">{member.email}</div>

                    {member.phone && (
                      <div className="text-muted-foreground lg:ml-auto">
                        {member.phone}
                      </div>
                    )}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
