import { SignOutButton } from '@clerk/clerk-react';
import { Button, Link } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { LanguageSelectorHorizontal } from '~/components/language';
import { useAuth } from '~/hooks/auth';
import { useCopy } from '~/hooks/copy';
import { usePlan } from '~/hooks/plan';

export const SettingsPage = () => {
  const { user } = useAuth();
  const copy = useCopy();
  const { hasEnterprise } = usePlan();

  if (!user) return null;

  return (
    <DashboardLayout headerTitle={copy.get('settings')}>
      <div className="mx-auto max-w-5xl space-y-6">
        <div className="divide-y divide-muted rounded-xl border">
          <div className="space-y-4 p-8">
            <div className="text-lg font-bold md:text-xl">{user.name}</div>

            <div>{user.email}</div>
          </div>

          {hasEnterprise() && (
            <div className="space-y-6 p-8">
              <LanguageSelectorHorizontal />

              <div>
                <Link href="/settings/pronunciations">
                  <Button
                    className="px-0"
                    variant="link"
                    size="sm"
                    label="editPronunciations"
                  />
                </Link>
              </div>
            </div>
          )}

          <div className="p-8 text-right">
            <SignOutButton>
              <Button variant="outline" size="md" label="signOut" />
            </SignOutButton>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
