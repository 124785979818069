import { useQuery } from '@tanstack/react-query';
import { listBoards } from '~/api';
import { BasicButton, Link } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';

export const BoardsPage = () => {
  const copy = useCopy();

  const { data: boards } = useQuery({
    queryKey: ['boards'],
    queryFn: async () => {
      const { boards } = await listBoards({});

      return boards;
    },
  });

  if (!boards) return null;

  return (
    <DashboardLayout
      headerTitle={copy.get('boards')}
      headerOptions={
        <>
          <Link href="/boards/new">
            <BasicButton size="sm">
              <svg
                className="-ml-2 mr-4 size-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <span>
                <span className="hidden md:inline">Add </span>

                <span>Board</span>
              </span>
            </BasicButton>
          </Link>
        </>
      }
    >
      <div className="mx-auto max-w-5xl space-y-4">
        {boards.map((board) => {
          return (
            <div
              className="rounded-xl transition-colors dark:bg-muted/50 hover:dark:bg-muted/70"
              key={board.id}
            >
              <Link href={`/boards/${board.id}`}>
                <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-8 xl:py-10">
                  <div className="font-semibold">{board.name}</div>
                </div>
              </Link>
            </div>
          );
        })}

        {boards.length === 0 && (
          <div className="text-balance rounded-lg border px-6 py-20 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noBoards')}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};
