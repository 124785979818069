import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { deleteIncident, getIncident, updateIncident } from '~/api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Badge,
  BasicButton,
  Link,
  OriginalButton,
  Separator,
} from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { IncidentAudioPlayer } from '~/components/incident';
import { IncidentMessages } from '~/components/incident-message';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { useOrg } from '~/hooks/org';
import { invalidateQueries } from '~/query';
import { sentry } from '~/sentry';
import { formatDate } from '~/utils';

export const IncidentPage = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const { isOwner } = useOrg();
  const copy = useCopy();
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ['incidents', incidentId],
    queryFn: async () => {
      const { incident, incidentAudio } = await getIncident({ incidentId });

      return { incident, incidentAudio };
    },
    refetchInterval: 5000,
  });

  const incident = data?.incident || null;
  const incidentAudio = data?.incidentAudio || null;

  const resolve = useMutation({
    mutationFn: async () => {
      const isResolved = !incident?.isResolved;

      analytics.track(isResolved ? 'incident.resolve' : 'incident.unresolve');

      await updateIncident({
        incidentId,
        updateData: {
          isResolved,
        },
      });
    },
    onSuccess: () => {
      invalidateQueries(['incident', incidentId]);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const remove = useMutation({
    mutationFn: async () => {
      await deleteIncident({
        incidentId,
      });
    },
    onSuccess: () => {
      analytics.track('incident.delete');

      navigate('/incidents');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  if (!incident) return null;

  return (
    <DashboardLayout
      title={
        incident.title ||
        `New Incident - ${dayjs.unix(incident.date).format('D MMM YY')}`
      }
      back="/incidents"
    >
      <div className="mx-auto w-full max-w-5xl space-y-6">
        <div className="rounded-lg border">
          <>
            <div className="bg-muted/50 px-4 py-3 text-sm font-medium">
              {copy.get('incident')}
            </div>

            <div className="flex flex-col gap-6 p-6 md:flex-row md:items-center md:gap-10">
              <div className="h-full space-y-3">
                {incident.isResolved && <Badge>{copy.get('resolved')}</Badge>}

                <h1 className="text-2xl font-bold md:text-3xl md:leading-normal">
                  {incident.title ||
                    `New Incident - ${dayjs.unix(incident.date).format('D MMM YY')}`}
                </h1>
              </div>
            </div>
          </>

          <Separator />

          <>
            <div className="space-y-6 p-6">
              <div className="space-y-2">
                <div className="font-bold">{copy.get('date')}</div>

                <div className="text-muted-foreground">
                  {formatDate(incident.date)}
                </div>
              </div>

              {incident.location && (
                <div className="space-y-2">
                  <div className="font-bold">{copy.get('location')}</div>

                  <div className="text-muted-foreground">
                    {incident.location}
                  </div>
                </div>
              )}

              <div className="space-y-2">
                <div className="font-bold">{copy.get('description')}</div>

                {incident.description && (
                  <div className="text-muted-foreground">
                    {incident.description}
                  </div>
                )}
              </div>
            </div>
          </>

          {incident.summary && (
            <>
              <Separator />

              <div className="space-y-6 p-6">
                <div className="font-bold">{copy.get('summary')}</div>

                <div className="leading-relaxed text-muted-foreground">
                  {incident.summary}
                </div>
              </div>

              <div className="px-6 pb-6">
                <IncidentAudioPlayer incidentAudio={incidentAudio} />
              </div>
            </>
          )}

          {isOwner() && (
            <>
              <Separator />

              <div className="flex flex-col flex-wrap gap-6 p-6 md:flex-row md:p-8">
                {!incident.isResolved && (
                  <a href={`/incidents/${incident.id}/edit`}>
                    <BasicButton className="w-full" variant="outline" size="sm">
                      {copy.get('edit')}
                    </BasicButton>
                  </a>
                )}

                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <BasicButton
                      variant="outline"
                      size="sm"
                      disabled={resolve.isPending}
                    >
                      {resolve.isPending ? (
                        <Loader2 className="h-6 w-6 animate-spin" />
                      ) : incident.isResolved ? (
                        copy.get('unresolve')
                      ) : (
                        copy.get('resolve')
                      )}
                    </BasicButton>
                  </AlertDialogTrigger>

                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        {copy.get('areYouSure')}
                      </AlertDialogTitle>

                      <AlertDialogDescription className="pb-6">
                        {incident.isResolved
                          ? copy.get('actionUnresolveIncident')
                          : copy.get('actionResolveIncident')}
                      </AlertDialogDescription>
                    </AlertDialogHeader>

                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>

                      <AlertDialogAction asChild>
                        <OriginalButton
                          onClick={() => {
                            resolve.mutate();
                          }}
                        >
                          {incident.isResolved
                            ? copy.get('unresolve')
                            : copy.get('resolve')}
                        </OriginalButton>
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>

                {incident.isResolved && (
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <BasicButton
                        variant="destructive"
                        size="sm"
                        disabled={remove.isPending}
                      >
                        {remove.isPending ? (
                          <Loader2 className="h-6 w-6 animate-spin" />
                        ) : (
                          copy.get('delete')
                        )}
                      </BasicButton>
                    </AlertDialogTrigger>

                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          {copy.get('areYouSure')}
                        </AlertDialogTitle>

                        <AlertDialogDescription className="pb-6">
                          {copy.get('actionDeleteIncident')}
                        </AlertDialogDescription>
                      </AlertDialogHeader>

                      <AlertDialogFooter>
                        <AlertDialogCancel>
                          {copy.get('cancel')}
                        </AlertDialogCancel>

                        <AlertDialogAction asChild>
                          <OriginalButton
                            onClick={() => {
                              remove.mutate();
                            }}
                          >
                            {copy.get('deleteIncident')}
                          </OriginalButton>
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                )}

                <Link href={`/incidents/${incident.id}/alerts`}>
                  <BasicButton className="w-full" variant="outline" size="sm">
                    {copy.get('viewAlerts')}
                  </BasicButton>
                </Link>

                {!incident.isResolved && isOwner() && (
                  <Link href={`/incidents/${incident.id}/send-alert`}>
                    <BasicButton className="w-full" variant="outline" size="sm">
                      {copy.get('sendAlert')}
                    </BasicButton>
                  </Link>
                )}
              </div>
            </>
          )}
        </div>

        <IncidentMessages className="pt-10" />
      </div>
    </DashboardLayout>
  );
};
