import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { createContent, onContentUpdate } from '~/api';
import { Form, FormInput, FormSubmitButton, FormSwitch } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { useUser } from '~/hooks/auth';
import { useCopy } from '~/hooks/copy';
import { useLanguage } from '~/hooks/language';
import { getOrgId } from '~/org';
import { sentry } from '~/sentry';
import { dateToCurrentUnix } from '~/utils';

export const CreateContentPage = () => {
  const [title, setTitle] = useState('');
  const navigate = useNavigate();
  const copy = useCopy();
  const { language } = useLanguage();
  const { user } = useUser();

  const [autoGenerateAudio, setAutoGenerateAudio] = useState(false);

  const create = useMutation({
    mutationFn: async () => {
      const orgId = getOrgId();

      const { content } = await createContent({
        title: title || `Content - ${dayjs().format('D MMM YYYY')}`,
        autoGenerateAudio,
        date: dateToCurrentUnix(new Date()),
      });

      await onContentUpdate({
        orgId,
        userId: user.id,
        contentId: content.id,
        languageId: language.id,
      });

      return content;
    },
    onSuccess: async (content) => {
      analytics.track('content.create');

      navigate(`/content/${content.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <DashboardLayout headerTitle={copy.get('addContent')} size="sm">
      <Form
        onSubmit={() => {
          create.mutate();
        }}
      >
        <FormInput
          value={title}
          onChange={setTitle}
          label="contentTitle"
          placeholder="title"
        />

        <FormSwitch
          id="auto-generate-audio"
          label="audio"
          description="autoGenerateAudioDescription"
          value={autoGenerateAudio}
          onChange={setAutoGenerateAudio}
        />

        <FormSubmitButton isLoading={create.isPending} label="addContent" />
      </Form>
    </DashboardLayout>
  );
};
