import { useEffect, useState } from 'react';
import { getReminder } from '~/api';
import { Redirect } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { Share } from '~/components/share';
import { Reminder } from '~/db';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { useShare } from '~/hooks/share';

export const ShareReminderPage = () => {
  const { reminderId } = useParams<{ reminderId: string }>();
  const [reminder, setReminder] = useState<Reminder | null>(null);

  useEffect(() => {
    const fn = async () => {
      const { reminder } = await getReminder({ reminderId });

      setReminder(reminder);
    };

    fn();
  }, [reminderId]);

  if (!reminder) return null;

  return <ShareReminder reminder={reminder} />;
};

const ShareReminder = ({ reminder }: { reminder: Reminder }) => {
  const copy = useCopy();
  const { canShare } = useShare(reminder);

  if (!canShare) return <Redirect path="/reminders" />;

  return (
    <DashboardLayout headerTitle={copy.get('shareReminder')} back="/reminders">
      <Share
        resourceType="reminder"
        resourceId={reminder.id}
        saveSuccessPath="/reminders"
      />
    </DashboardLayout>
  );
};
