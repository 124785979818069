import { useQuery } from '@tanstack/react-query';
import { getContent } from '~/api';
import { Redirect } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { Share } from '~/components/share';
import { Content } from '~/db';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { useShare } from '~/hooks/share';

export const ShareContentPage = () => {
  const { contentId } = useParams<{ contentId: string }>();

  const { data } = useQuery({
    queryKey: ['content', contentId],
    queryFn: async () => {
      const { content } = await getContent({
        contentId,
      });

      return { content };
    },
  });
  const content = data?.content;

  if (!content) return null;

  return <ShareContent content={content} />;
};

export const ShareContent = ({ content }: { content: Content }) => {
  const copy = useCopy();
  const { canShare } = useShare(content);

  if (!canShare) return <Redirect path="/content" />;

  return (
    <DashboardLayout
      headerTitle={copy.get('shareContent')}
      back={`/content/${content.id}/settings`}
      size="full"
    >
      <Share
        resourceType="content"
        resourceId={content.id}
        saveSuccessPath={`/content/${content.id}`}
      />
    </DashboardLayout>
  );
};
