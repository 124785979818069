import { motion } from 'framer-motion';
import { Link } from '~/components';
import { cn } from '~/style';

export const DashboardPageHeader = ({
  title,
  back,
  options,
  size,
}: {
  title?: string;
  back?: string;
  options?: React.ReactNode;
  isFullWidth?: boolean;
  size?: 'sm' | 'full';
}) => {
  return (
    <div
      className={cn('px-6 md:pt-10', {
        'md:pt-0': size === 'full',
      })}
    >
      <div
        className={cn(
          'mx-auto flex h-20 max-w-5xl items-center gap-4 lg:h-28',
          {
            'max-w-2xl': size === 'sm',
            'max-w-none pt-0': size === 'full',
          },
        )}
      >
        {back && (
          <Link href={back}>
            <motion.div
              className="-ml-1 mt-1 p-2"
              initial={{ x: 0 }}
              whileHover={{
                x: -2,
              }}
            >
              <svg
                className="size-6 lg:size-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                />
              </svg>
            </motion.div>
          </Link>
        )}

        {title && (
          <div className="text-lg font-semibold md:text-2xl lg:text-3xl">
            {title}
          </div>
        )}

        {options && <div className="ml-auto flex gap-4">{options}</div>}
      </div>
    </div>
  );
};
