import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { createReminder } from '~/api';
import { Form, FormInput, FormSubmitButton, FormTextarea } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { Schedule } from '~/components/reminder';
import { useCopy } from '~/hooks/copy';
import { sentry } from '~/sentry';

export const CreateReminderPage = () => {
  const [title, setTitle] = useState('');
  const [frequency, setFrequency] = useState('');
  const [days, setDays] = useState('');
  const [dates, setDates] = useState('');
  const [months, setMonths] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const copy = useCopy();

  const create = useMutation({
    mutationFn: async () => {
      await createReminder({
        title,
        description,
        frequency,
        days,
        dates,
        months,
      });
    },
    onSuccess: async () => {
      analytics.track('reminder.create');

      navigate('/reminders');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const submitCreate = () => {
    if (!title) return;

    create.mutate();
  };

  return (
    <DashboardLayout headerTitle={copy.get('newReminder')} size="sm">
      <div className="mx-auto w-full max-w-2xl space-y-6 md:space-y-10 lg:space-y-14">
        <Form onSubmit={submitCreate}>
          <FormInput
            value={title}
            onChange={setTitle}
            label="reminderTitle"
            placeholder="title"
          />

          <FormTextarea
            value={description}
            onChange={setDescription}
            label="description"
            placeholder="description"
          />

          <Schedule
            frequency={frequency}
            setFrequency={setFrequency}
            days={days}
            setDays={setDays}
            dates={dates}
            setDates={setDates}
            months={months}
            setMonths={setMonths}
          />
        </Form>

        <FormSubmitButton
          isLoading={create.isPending}
          label="createReminder"
          onClick={submitCreate}
        />
      </div>
    </DashboardLayout>
  );
};
