import { useQuery } from '@tanstack/react-query';
import { listContents } from '~/api';
import { BasicButton, Link, Separator } from '~/components';
import { Contents } from '~/components/content';
import { DashboardLayout } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';

export const ContentsPage = () => {
  const copy = useCopy();

  const { data } = useQuery({
    queryKey: ['content'],
    queryFn: async () => {
      const { contents, sharedContents } = await listContents({
        filter: {
          isArchived: false,
        },
      });

      return { contents, sharedContents };
    },
  });

  if (!data) return null;

  const contents = data.contents;
  const sharedContents = data.sharedContents;
  const hasNoContents = contents.length === 0 && sharedContents.length === 0;

  return (
    <DashboardLayout
      headerTitle={copy.get('contents')}
      headerOptions={
        <>
          <Link href="/content/new">
            <BasicButton size="sm">
              <svg
                className="-ml-2 mr-4 size-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <span>
                <span className="hidden md:inline">Add </span>

                <span>Content</span>
              </span>
            </BasicButton>
          </Link>
        </>
      }
    >
      <div className="space-y-10 md:space-y-12">
        {hasNoContents && (
          <div className="mx-auto max-w-5xl text-balance rounded-lg border py-20 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noContent')}
          </div>
        )}

        <Contents contents={contents} />

        {sharedContents.length > 0 && (
          <>
            {contents.length > 0 && (
              <div className="md:py-10">
                <Separator />
              </div>
            )}

            <div className="mx-auto max-w-5xl space-y-6 md:space-y-8">
              <h2 className="text-lg md:text-xl">
                {copy.get('sharedWithYou')}
              </h2>

              <Contents contents={sharedContents} />
            </div>
          </>
        )}
      </div>

      {/* <ActionButton href="/content/new" /> */}
    </DashboardLayout>
  );
};
