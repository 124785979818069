import { useQuery } from '@tanstack/react-query';
import { listMemberInvites } from '~/api';
import { Separator } from '~/components';
import { useCopy } from '~/hooks/copy';
import { numberWithCommas } from '~/utils';

export const MemberInvites = ({
  showSeparator,
}: {
  showSeparator?: boolean;
}) => {
  const copy = useCopy();

  const { data: invites } = useQuery({
    queryKey: ['members', 'invites'],
    queryFn: async () => {
      const { invites } = await listMemberInvites({});

      return invites;
    },
  });

  if (!invites?.length) return null;

  return (
    <>
      {showSeparator && <Separator className="my-10 md:my-20" />}

      <div className="mx-auto w-full max-w-5xl space-y-6">
        <div className="flex flex-col gap-6 rounded-lg border p-6 md:flex-row md:items-center">
          <h2 className="text-lg font-bold">
            {copy.get('invites')} ({numberWithCommas(invites.length)})
          </h2>
        </div>

        <div className="mx-auto w-full max-w-5xl overflow-hidden rounded-lg border">
          {invites.map((invite, inviteIndex) => {
            return (
              <div key={invite.id}>
                {inviteIndex !== 0 && <Separator />}

                <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:gap-14 lg:py-6 xl:py-8">
                  <div className="flex items-center gap-4">
                    <div className="font-semibold">{invite.email}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
