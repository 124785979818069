import { LiveList } from '@liveblocks/client';
import { ResourceType } from '~/db';

export const getRoomId = ({
  orgId,
  resourceType,
  resourceId,
}: {
  orgId: string;
  resourceType: ResourceType;
  resourceId: string;
}) => {
  return `${orgId}:${resourceType}:${resourceId}`;
};

export const parseRoomId = (roomId: string) => {
  const [orgId, resourceType, resourceId] = roomId.split(':');

  return {
    orgId,
    resourceType: resourceType as ResourceType,
    resourceId,
  };
};

export const getInitialRoomStorage = () => {
  return {
    items: new LiveList([]),
    stages: new LiveList([]),
  };
};
