import { useQuery } from '@tanstack/react-query';
import { getIncidentAlert, listMemberIncidentAlerts } from '~/api';
import { Badge, Separator } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { useParams } from '~/hooks';
import { useUser } from '~/hooks/auth';
import { useCopy } from '~/hooks/copy';
import { cn } from '~/style';
import { formatDateTime } from '~/utils';

export const IncidentAlertPage = () => {
  const { incidentId, incidentAlertId } = useParams<{
    incidentId: string;
    incidentAlertId: string;
  }>();
  const { user } = useUser();
  const copy = useCopy();

  const { data: alert } = useQuery({
    queryKey: ['incidents', 'alerts', incidentAlertId],
    queryFn: async () => {
      const { incidentAlert } = await getIncidentAlert({ incidentAlertId });

      return incidentAlert;
    },
    refetchInterval: 5000,
  });

  const { data: memberAlerts } = useQuery({
    queryKey: ['incidents', 'memberAlerts', incidentAlertId],
    queryFn: async () => {
      const { memberIncidentAlerts } = await listMemberIncidentAlerts({
        incidentAlertId,
      });

      return memberIncidentAlerts;
    },
    refetchInterval: 5000,
  });

  if (!alert) return null;

  return (
    <DashboardLayout
      headerTitle={copy.get('alert')}
      back={`/incidents/${incidentId}/alerts`}
    >
      <div className="mx-auto max-w-5xl space-y-6">
        <div className="rounded-lg border">
          <div className="space-y-6 p-6">
            <div className="space-y-2">
              <div className="text-lg font-bold">{copy.get('date')}</div>

              <div className="text-muted-foreground">
                {formatDateTime(alert.updated, 'HH:mm - D MMM YYYY')}
              </div>
            </div>

            <div className="space-y-2">
              <div className="text-lg font-bold">{copy.get('status')}</div>

              <div>
                <div
                  className={cn(
                    'mt-2 inline-block rounded-full px-5 py-1 text-center text-sm font-semibold',
                    {
                      'bg-[rgb(43,172,110)] text-white':
                        alert.status === 'complete',
                      'bg-gray-600 text-white': alert.status !== 'complete',
                    },
                  )}
                >
                  {alert.status === 'complete'
                    ? copy.get('sent')
                    : copy.get('sending')}
                </div>
              </div>
            </div>
          </div>
        </div>

        {memberAlerts?.length !== 0 && (
          <>
            <div className="pt-10">
              <h2 className="text-xl font-bold">
                {copy.get('members')} ({memberAlerts?.length || 0})
              </h2>
            </div>

            <div className="overflow-hidden rounded-lg border">
              {memberAlerts?.map((memberAlert, memberAlertIndex) => {
                const isCurrentUser = memberAlert.memberId === user.id;

                if (!memberAlert.member) return null;

                return (
                  <div key={memberAlert.id}>
                    {memberAlertIndex !== 0 && <Separator />}

                    <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-6 xl:py-8">
                      <div className="space-y-2">
                        <div className="flex items-center gap-4">
                          <div className="text-lg">
                            {memberAlert.member.name}
                          </div>

                          {isCurrentUser && (
                            <div>
                              <Badge variant="secondary">
                                {copy.get('you')}
                              </Badge>
                            </div>
                          )}
                        </div>

                        <div className="text-muted-foreground">
                          {memberAlert.member.email}
                        </div>

                        {memberAlert.member.phone && (
                          <div className="text-muted-foreground">
                            {memberAlert.member.phone}
                          </div>
                        )}
                      </div>

                      <div className="ml-auto mt-8 flex items-center gap-6 md:mt-0">
                        {memberAlert.status === 'complete' && (
                          <div className="ml-auto">
                            {formatDateTime(memberAlert.updated, 'HH:mm')}
                          </div>
                        )}

                        <div
                          className={cn(
                            'inline-block rounded-full px-5 py-1 text-center text-sm font-semibold',
                            {
                              'bg-[rgb(43,172,110)] text-white':
                                memberAlert.status === 'complete',
                              'bg-gray-600 text-white':
                                memberAlert.status !== 'complete',
                            },
                          )}
                        >
                          {memberAlert.status === 'complete'
                            ? copy.get('sent')
                            : copy.get('sending')}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};
