import { Query, Response } from '~/components/ask';
import { DashboardLayout } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';

export const AskPage = () => {
  const copy = useCopy();

  return (
    <DashboardLayout headerTitle={copy.get('ask')}>
      <Query />

      <Response />
    </DashboardLayout>
  );
};
