import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { createGroup } from '~/api';
import { Form, FormInput, FormSubmitButton } from '~/components';
import { DashboardLayout } from '~/components/dashboard';
import { MembersSelect } from '~/components/member';
import { useCopy } from '~/hooks/copy';
import { useMembers, useSelectMembers } from '~/hooks/member';
import { sentry } from '~/sentry';

export const CreateGroupPage = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const copy = useCopy();
  const { memberIds, setMemberId } = useSelectMembers([]);
  const { members } = useMembers({ omitCurrentUser: true });

  const create = useMutation({
    mutationFn: async () => {
      await createGroup({
        name,
        memberIds,
      });
    },
    onSuccess: async () => {
      analytics.track('group.create');

      navigate('/groups');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const submitCreate = () => {
    if (!name) return;

    create.mutate();
  };

  return (
    <DashboardLayout headerTitle={copy.get('newGroup')} size="sm">
      <div className="mx-auto w-full max-w-2xl space-y-6 md:space-y-10 lg:space-y-14">
        <Form onSubmit={submitCreate}>
          <FormInput
            value={name}
            onChange={setName}
            label="groupName"
            placeholder="name"
          />
        </Form>

        {members && (
          <div className="space-y-4">
            <div>{copy.get('members')}</div>

            <MembersSelect
              members={members}
              memberIds={memberIds}
              onMemberSelect={setMemberId}
            />
          </div>
        )}

        <FormSubmitButton
          isLoading={create.isPending}
          label="createGroup"
          onClick={submitCreate}
        />
      </div>
    </DashboardLayout>
  );
};
